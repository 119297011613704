import { useState, useEffect, useRef } from "react"; // Include useRef
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Include your custom CSS

function App() {
  const [imageBlob, setImageBlob] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isGeneratingImage = useRef(false);
  const [jobId, setJobId] = useState(null); // Add state to store Job ID
  const [apiStatus, setApiStatus] = useState(null);  // Add this line near your other useState declarations
  const myAPIKey = process.env.REACT_APP_MY_API_KEY;

  const descriptions = [
      { trait: '', probability: 0.3 },
      { trait: 'japanese', probability: 0.2 },
      { trait: 'black african', probability: 0.2 },
      { trait: 'mexican', probability: 0.2 },
      { trait: 'smiling', probability: 0.1 },
      {trait: 'indigenous', probability: 0.2 },

      // You can add more traits with their respective probabilities here
  ];

  const places = [
      { trait: '', probability: 0.6 },
      { trait: 'in the jungle', probability: 0.1 },
      { trait: 'in a desert', probability: 0.1 },
      { trait: 'in a spaceship', probability: 0.1 },
      { trait: 'in an artists workplace', probability: 0.1 },
      // You can add more traits with their respective probabilities here
  ];



  const genres = [
        { trait: '', probability: 0.5 },  // default genre with higher probability
        { trait: 'Science-fiction Cyberpunk', probability: 0.2 },
        { trait: 'horror', probability: 0.1 },
        { trait: 'Science-fiction', probability: 0.2 },
        // You can add more genres with their respective probabilities here
    ];

  function selectTrait(traits) {
      const random = Math.random();
      let sum = 0;

      for (let i = 0; i < traits.length; i++) {
          sum += traits[i].probability;
          if (random <= sum) {
              return traits[i].trait;
          }
      }

      return '';  // default if no trait matches, though this should be rare due to probabilities
  }
  


  const checkJobStatus = async (id) => {
    try {
      const response = await axios.get(`https://api.runpod.ai/v2/wyzjwovnw3n0pe/status/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}` 
        },
      });

      console.log(`Received status update for job ID ${jobId}: `, response.data);

      if (response.data && response.data.status) {
        setApiStatus(response.data.status);  // Update the API status
      }
      
      if (response.data && response.data.output && response.data.output.images) {
        // Process the image like before
        const imageBytes = atob(response.data.output.images[0]); // Assuming images is an array and you're interested in the first image
        const bytes = new Uint8Array(imageBytes.length);
        for (let i = 0; i < imageBytes.length; i++) {
          bytes[i] = imageBytes.charCodeAt(i);
        }
        const blob = new Blob([bytes.buffer], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setImageBlob(url);
  
        setIsLoading(false);
        isGeneratingImage.current = false;
        setJobId(null); // Reset jobId
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await axios.get('https://automatic1111.8horses.ch/sdapi/v1/progress?skip_current_image=true', {
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.data.progress === 1) {
        setIsLoading(false); // Stop loading
        isGeneratingImage.current = false; // Set isGeneratingImage to false
      } else if (!isGeneratingImage.current) { // If isGeneratingImage is false and progress is not 1
        setIsLoading(true); // Start loading
        isGeneratingImage.current = true; // Set isGeneratingImage to true
      }
      return response.data;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchIntermediateImage = async () => {
    try {
      const response = await axios.get('https://automatic1111.8horses.ch/sdapi/v1/progress?skip_current_image=false', {
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.data && response.data.current_image) {
        // Decoding base64 image and converting to Blob
        const imageBytes = atob(response.data.current_image);
        const bytes = new Uint8Array(imageBytes.length);
        for (let i = 0; i < imageBytes.length; i++) {
          bytes[i] = imageBytes.charCodeAt(i);
        }
        const blob = new Blob([bytes.buffer], { type: 'image/png' });

        const url = URL.createObjectURL(blob);
        setImageBlob(url);
      }
    } catch (err) {
      console.error(err);
    }
  };



  const generateArt = async () => {
    setIsLoading(true);
    isGeneratingImage.current = true; // Set isGeneratingImage to true

    const description = selectTrait(descriptions);
    const genre = selectTrait(genres);
    const place = selectTrait(places);

    const promptText = `an intriguing beautiful film still showing an epic scene ${place} containing a ${description} woman with Pink hair from the ${genre} movie elctrchld. Analog style. (high detailed skin_1.2), 8k uhd, realistic, cinematic, soft light`;

    console.log("Selected Description:", description);  // Logging selected description
    console.log("Selected Place:", place);  // Logging selected description
    console.log("Selected Genre:", genre);  // Logging selected genre
    console.log("Generated Prompt:", promptText);  // Logging the full prompt


    try {
      console.log("Sending request to API");
      const payload = {
        "input": {
          "api_name": "txt2img",
          "prompt": promptText,
          "negative_prompt": "bare breasts, deformed, deformed face, weird eyes, deformed limbs, ugly hands, deformed hands, blown out highlights", // Note: replaced "negative prompt" with "negative_prompt" for JSON key
          "denoising_strength": 0.4,
          "enable_hr": true,
          "hr_resize_x": 1536,
          "hr_resize_y": 1024,
          "hr_scale": 2,
          "hr_second_pass_steps": 20,
          "hr_upscaler": "Latent",
          "hr_sampler_name": "Euler a",
          "sampler_name": "Euler a",
          "steps": 30,
          "width": 768
        }
      };
  
      const response = await axios.post(
        `https://api.runpod.ai/v2/wyzjwovnw3n0pe/run`,
        payload, // Using the new payload
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${myAPIKey}`  // Replace with your actual API token
          },
        }
      );
      
      console.log("Received response from API", response.data);

      if (response.data && response.data.status) {
        setApiStatus(response.data.status);  // Set initial API status
      }
  
      if (response.data && response.data.id) {
        console.log(`Job ID is set as ${response.data.id}`); // Log the jobId
        setJobId(response.data.id); // Store the Job ID
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    const interval = setInterval(async () => {
      if (jobId) { // Only proceed if jobId is set
        console.log(`About to check status for job ID ${jobId}`); // Log the jobId
        await checkJobStatus(jobId);
      }
    }, 5000);
  
    return () => clearInterval(interval);
  }, [jobId]); // Use jobId as the dependency instead of isLoading
  
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-2">
      <h1 className="text-4xl font-extrabold">The Heroine with 1000 Faces</h1>
      <div className="flex flex-col items-center justify-center gap-2 mb-6">
        <button onClick={generateArt} className="bg-black text-white rounded-md p-2">Generate</button>
        {
          
        }
      </div>
      <div className="image-container relative w-full">
        {
           imageBlob && <img src={imageBlob} className="generated-image" alt="AI generated art" />
        }
        {isLoading &&
          <div className="status-text-container">
            {/* Display API status here */}
            {apiStatus && <p>{`Status: ${apiStatus}`}</p>}
          </div>
        }
      </div>
    </div>
  );
}

export default App;
